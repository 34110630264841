<template>
  <li
    role="option"
    draggable="true"
    aria-describedby="reorder_instructions"
    tabindex="0"
    :class="`sortable_list_${item_type}__item even:bg-cool-white odd:bg-light-gray first:rounded-t last:rounded-b cursor-move`"
    @keydown="handleKeydown"
  >
    <div class="flex items-center justify-between p-2">
      <div class="flex-none mr-2 cursor-move">
        <font-awesome-icon icon="grip-lines" />
      </div>
      <div class="grow cursor-move">
        <p>{{ item[name_field] }}</p>
      </div>
      <div>
        <button :data-cy="`remove_${item_type}_${index}`" @click="$emit('remove', index)" class="text-dark-purple">
          Remove
        </button>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: "DraggableListItem",
  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    item_type: {
      type: String,
      required: true,
    },
    name_field: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      grabbed: false,
    };
  },
  methods: {
    handleKeydown(e) {
      switch (e.key) {
        case " ":
          e.preventDefault();
          console.log("space");
          if (this.grabbed) {
            this.grabbed = false;
            this.$emit("dropped", {
              draggedItem: this.item,
              dropIndex: this.index,
            });
          } else {
            this.grabbed = true;
            this.$emit("grabbed", {
              item: this.item,
              index: this.index,
            });
          }
          break;
        case "ArrowUp":
          if (this.grabbed) {
            e.preventDefault();
            console.log("up");
            this.$emit("move", {
              dragIdx: this.index,
              hoverIdx: this.index - 1,
            });
          }
          break;
        case "ArrowDown":
          if (this.grabbed) {
            e.preventDefault();
            console.log("down");
            this.$emit("move", {
              dragIdx: this.index,
              hoverIdx: this.index + 1,
            });
          }
          break;
        case "Tab":
          if (this.grabbed) {
            e.preventDefault();
            console.log();
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
