<template>
  <div class="rounded">
    <div class="sr-only">
      <button
        class="text-sm underline focus:outline-none"
        @click="showKeyboardInstruction = !showKeyboardInstruction"
      >
        {{
          `${showKeyboardInstruction ? "Hide" : "Show"} Keyboard Instructions`
        }}
      </button>
      <p v-if="showKeyboardInstruction" class="text-sm">
        Select an item by pressing the spacebar to grab it. Press the up and
        down arrows to change the item's position, press the spacebar again to
        drop the item.
      </p>
      <div aria-live="assertive" v-text="assistiveText" />
    </div>
    <draggable
      v-model="draggableList"
      :animation="248"
      tag="ol"
      role="listbox"
      :item-key="identifier"
      :aria-label="'draggable item'"
    >
      <template #item="{ element, index }">
        <DraggableListItem
          :key="index"
          :index="index"
          :item_type="list_of"
          :name_field="name_field"
          :item="element"
          @remove="$emit('remove', index)"
        />
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import DraggableListItem from "@/components/draggable/DraggableListItem";
export default {
  name: "DraggableList",
  components: {
    DraggableListItem,
    draggable,
  },
  props: {
    modelValue: { type: Array, required: true },
    name_field: { type: String, default: "description" },
    identifier: { type: String, default: "id" },
    list_of: { type: String, required: true },
  },
  data() {
    return {
      items: this.value,
      assistiveText: "",
      showKeyboardInstruction: false,
    };
  },
  computed: {
    draggableList: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
