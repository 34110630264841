<template>
  <div
    :class="{
      'w-full rounded p-3 mb-2': true,
      'bg-light-purple bg-opacity-30 text-dark-purple': level === 'info',
      'bg-alert-danger bg-opacity-20 text-error-text': level === 'error',
      'bg-alert-warn bg-opacity-30 text-alert-warn': level === 'warn',
      'bg-alert-success bg-opacity-30 text-alert-success-heavy':
        level === 'success',
    }"
  >
    <slot name="title">
      <div
        :class="{
          'font-semibold text-lg flex justify-start items-center mb-3 pb-1 border-b': true,
          'border-dark-purple': level === 'info',
          'border-error-text': level === 'error',
          'border-alert-warn': level === 'warn',
          'border-alert-success-heavy': level === 'success',
        }"
      >
        <font-awesome-icon icon="exclamation-triangle" class="mr-1" />
        <p>{{ title }}</p>
      </div>
    </slot>
    <slot name="body">
      <div class="pl-3">
        <p v-for="(msg, key) in messages" :key="key">
          {{ msg }}
        </p>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "ErrorBanner",
  props: {
    title: {
      type: String,
      default: "ALERT",
    },
    messages: {
      type: Array,
    },
    error: {
      type: Boolean,
      required: false,
    },
    warn: {
      type: Boolean,
      required: false,
    },
    success: {
      type: Boolean,
      required: false,
    },
    info: {
      type: Boolean,
      required: false,
    },
  },
  created() {
    this.$watch(
      (vm) => [vm.error, vm.warn, vm.info, vm.success],
      () => {
        if (this.info) {
          this.level = "info";
        } else if (this.warn) {
          this.level = "warn";
        } else if (this.error) {
          this.level = "error";
        } else if (this.success) {
          this.level = "success";
        } else {
          this.level = "info";
        }
      }
    );
  },
  data() {
    return {
      level: "info",
    };
  },
};
</script>

<style scoped></style>
